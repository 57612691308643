import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import ClipLoader from "react-spinners/ClipLoader"
import { StoreContext } from "context/store"

import BlockContent from "components/block-content"
import BuildingBundleModal from "components/molecules/BuildingBundleModal"
import Image from "components/atoms/Image"
import { round, currencyFormat, calDiscountFlavor, isFutureDate } from "consts/helper"
import { DISCOUNTS, FLAVOR_UNIT, FLAVOR_UNITS, FRQ_WEEKS } from "consts"

import WhySubscribeModal from "components/molecules/WhySubscribeModal"
import gtag from "../../../utils/gtag"
import RebuyProducts from "components/molecules/Rebuy/RebuyProduts"
import QuantityPicker from "components/atoms/QuantityPicker"
import QuantityLabel from "components/atoms/QuantityLabel"
import useBlackFriday from "hooks/useBlackFriday"
import DiscountCompare from "components/atoms/DiscountCompare/DiscountCompare"
import FlashSale from "../FlashSale"

const ProductInfoWrapper = ({ product, shopifyVariants, setActiveFlavorImageKey, setCurQty, activeFlavor }) => {
  const { addToCart, isAddingProductInCart, isReBuyPopupOpen, setReBuyPopupOpen } = useContext(StoreContext)

  const { enableDiscount } = useBlackFriday()

  const { contains, flavors, _rawSubscriptionSettings, shopifyProductId } = product
  const { description, initialDiscount } = _rawSubscriptionSettings

  const [selFlavor, setSelFlavor] = useState(activeFlavor)
  const [isSubscribe, setIsSubscribe] = useState(false)
  const [showWhySubscribe, setShowWhySubscribe] = useState(false)
  const [subscribeId, setSubscribeId] = useState(null)
  const [selectQuantityWeeks, setSelectQuantityWeeks] = useState(1)
  const [isBuildingBundleModalOpen, setIsBuildingBundleModalOpen] = useState(0)
  const [discounted, setDiscounted] = useState(false)
  const [discountPer, setDiscountPer] = useState(0)
  const [quanitites, setQuantities] = useState(FLAVOR_UNITS.loaf)
  const [pickingType, setPickingType] = useState("")
  const [outOfStock, setOutOfStock] = useState(false)
  const [discounts, setDiscounts] = useState(DISCOUNTS)

  // This is used for when no discount tiers are enabled (sanity > enableDiscount = false)
  const [quantity, setQuantity] = useState(1)

  const normalQty = flavors[selFlavor]?.subQtyItems[selectQuantityWeeks - 1]?.subQty || 1

  const subQty = flavors[selFlavor]?.isFlavor ? normalQty : selectQuantityWeeks

  const varietyMul = normalQty / flavors[selFlavor]?.subQtyItems[0]?.subQty || 1

  const subQtyItem = flavors[selFlavor]?.subQtyItems[selectQuantityWeeks - 1]

  // selling plan
  const selectedVariant = shopifyVariants.find(variant => variant.sku === flavors[selFlavor].sku)
  const sellingPlans = selectedVariant?.fields?.sellingPlans?.sellingPlanGroups?.nodes[0]?.sellingPlans?.nodes

  useEffect(() => {
    if (flavors[selFlavor]?.unit && FLAVOR_UNITS[flavors[selFlavor].unit]) {
      setQuantities(FLAVOR_UNITS[flavors[selFlavor].unit])
    } else {
      setQuantities(FLAVOR_UNITS.loaf)
    }
    handleLoaves(selectQuantityWeeks - 1)
    setActiveFlavorImageKey(flavors[selFlavor].images[0]?._key)
  }, [flavors, selFlavor])

  useEffect(() => {
    setPickingType(flavors[0]?.pickingType)
  }, [flavors])

  useEffect(() => {
    const selFlavorSku = selectQuantityWeeks > 1 ? `${flavors[selFlavor].sku}-${varietyMul}` : flavors[selFlavor].sku
    let variantExist = false
    shopifyVariants.map(variant => {
      if (variant.sku === selFlavorSku) {
        setOutOfStock(variant.inventoryQuantity === 0)
        variantExist = true
      }
    })

    if (!flavors[selFlavor].allowDiscount) {
      variantExist = true
    }

    if (!variantExist) {
      setOutOfStock(true)
    }
  }, [selFlavor, selectQuantityWeeks])

  useEffect(() => {
    let correctSku = ""
    if (flavors[selFlavor].allowDiscount) {
      correctSku = `${flavors[selFlavor].sku}${selectQuantityWeeks > 1 ? `-${selectQuantityWeeks}` : ""}`
    } else {
      correctSku = flavors[selFlavor].sku
    }
    const selVariant = shopifyVariants.filter(variant => variant.sku === correctSku)[0]
    gtag("dl_view_item", {
      event_id: selVariant?.sku + Math.floor(Date.now() / 1000),
      ecommerce: {
        currencyCode: "USD",
        detail: {
          actionField: { list: location.pathname },
          products: [
            {
              id: selVariant?.sku,
              price: selVariant?.price,
              image: flavors[selFlavor].images[0]?.asset?.gatsbyImageData?.images?.fallback?.src || "",
              name: product.name,
              category: product.categories.join(", "),
              variant: selVariant?.title,
              sku: flavors[selFlavor].sku,
              brand: "Kiss My Keto",
              quantity: selectQuantityWeeks,
              product_id: shopifyProductId,
              variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
            },
          ],
        },
      },
    })
  }, [selFlavor])

  const handleAddToCart = async () => {
    let correctSku = ""

    if (flavors[selFlavor].allowDiscount) {
      correctSku = `${flavors[selFlavor].sku}${selectQuantityWeeks > 1 ? `-${varietyMul}` : ""}`
    } else {
      correctSku = flavors[selFlavor].sku
    }

    const checkoutQuantity = flavors[selFlavor].allowDiscount ? 1 : selectQuantityWeeks
    const selVariant = shopifyVariants.filter(variant => variant.sku === correctSku)[0]

    const inLineItem = {
      kmkIsSubscribe: isSubscribe,
      kmkPrice:
        flavors[selFlavor]?.allowDiscount && isSubscribe
          ? calcSubDiscountedPrice(flavors[selFlavor].price)
          : selVariant?.price,
      kmkImage: flavors[selFlavor].images[0],
      kmkTitle: selVariant?.title,
      title: product.name,
      variant: selVariant?.title,
      kmkSku: flavors[selFlavor].sku,
      kmkQuantity: 1,
      kmkDiscount: initialDiscount,
      kmkAllowDiscount: flavors[selFlavor]?.allowDiscount,
      selling_plan: isSubscribe ? subscribeId : null,
      fulfillment_service: "manual",
      order_interval_frequency: isSubscribe ? subscribeId * 15 : null,
      order_interval_unit: isSubscribe ? "day" : null,
      product_id: shopifyProductId,
      quantity: !enableDiscount ? quantity : 1,
      categories: product.categories,
      variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
      storefrontId: selVariant?.storefrontId,
      name: product.name,
      id: selVariant?.sku,
    }

    setReBuyPopupOpen(true)
    addToCart(inLineItem)
  }

  const calcOrgPrice = price => {
    return round(price * varietyMul, 2)
  }

  const calcDiscountedPrice = orgPrice => {
    const discountedPrice = ((orgPrice * (100 - discountPer)) / 100) * varietyMul
    return round(discountedPrice, 2)
  }

  const calDiscountedPricePerQuantity = (orgPrice, discount) => {
    let discountedPrice = (orgPrice * (100 - discount)) / 100
    const flavorFirstSubQty = flavors[selFlavor]?.subQtyItems[0].subQty
    // if (!flavors[selFlavor]?.isFlavor) {
    //   discountedPrice = discountedPrice / flavorFirstSubQty
    // }
    return round(discountedPrice / flavorFirstSubQty, 2)
  }

  const calcSubDiscountedPrice = orgPrice => {
    const discountedPrice = (calcDiscountedPrice(orgPrice) * (100 - initialDiscount)) / 100
    return round(discountedPrice, 2)
  }

  const setFlavor = flavor => {
    const flavorUrl = product._rawSlug.current + flavor.slug.current.slice(1)
    navigate(flavorUrl)
  }

  const handleLoaves = loaves => {
    const discounts = calDiscountFlavor(flavors[selFlavor], flavors)
    setDiscounts(discounts)
    if (flavors[selFlavor].allowDiscount) {
      if (!flavors[selFlavor]?.isFlavor) {
        setDiscounted(true)
        // setDiscountPer(discounts[loaves]?.value || 15)
        setDiscountPer(discounts[loaves]?.value)
      } else if (loaves === 0) {
        setDiscounted(false)
        setDiscountPer(0)
      } else {
        setDiscounted(true)
        setDiscountPer(discounts[loaves]?.value || 0)
      }
    } else {
      setDiscounted(false)
    }

    setSelectQuantityWeeks(loaves + 1)
    setCurQty(loaves)
  }

  const handleSubscribe = id => {
    setSubscribeId(id)
  }

  const totalPrice = !enableDiscount
    ? currencyFormat(calcOrgPrice(flavors[selFlavor].price * quantity))
    : currencyFormat(calcDiscountedPrice(flavors[selFlavor].price))

  // Dates for flash sale functionality
  const showFlashSale = isFutureDate(flavors[selFlavor].countdownStartDate)

  return (
    <>
      <div className="product-details-top-desktop">
        <h1 className="title text-left">{product.name}</h1>

        <div className="rating-wrapper">
          <span className="stamped-product-reviews-badge stamped-main-badge" data-id={product?.shopifyProductId}></span>
        </div>
        {isReBuyPopupOpen && <RebuyProducts onClose={() => setReBuyPopupOpen(false)} />}
        <div className="ingredients">
          {contains?.length > 0 &&
            contains.map((contain, idx) => {
              return (
                <div className="ingredients-inner" key={idx}>
                  {contain.name}
                </div>
              )
            })}
        </div>
      </div>

      {showFlashSale && (
        <FlashSale value={flavors[selFlavor].discountValue} endDate={flavors[selFlavor].countdownEndDate} />
      )}

      <div className="product-details-steps">
        <p className="subtitle">{`PICK YOUR ${pickingType}`}</p>
        <div className="product-flavor">
          {flavors?.map((flavor, idx) => {
            return (
              <div className="product-flv-item" key={idx} onClick={() => setFlavor(flavor)}>
                <div className={selFlavor === idx ? "product-flv-link active" : "product-flv-link"}>
                  <span className="pfi-img">
                    <Image image={flavor.flavorIcon.asset} className="flav-img" />
                  </span>
                  <span className="pfi-text">{flavor.flavor}</span>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {enableDiscount && (
        <div className="product-details-steps select-quantity">
          <p className="subtitle">SELECT YOUR QUANTITY</p>
          <div className="weeks">
            {quanitites.map((quantity, idx) => (
              <div className="weeks-inner" key={idx} onClick={() => handleLoaves(idx)}>
                <div className="stepper">
                  <div className="stepper-inner" key={idx}>
                    <div className={selectQuantityWeeks - 1 === idx ? "stepper-step active" : "stepper-step"}>
                      <span>{enableDiscount ? discounts[idx]?.text : null}</span>
                      <span className="check">
                        <i className={`icon-check ${enableDiscount ? "" : "invisible"}`} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={selectQuantityWeeks - 1 === idx ? "weeks-button active" : "weeks-button"}>
                  {flavors[selFlavor]?.subQtyItems?.length && (
                    <>
                      <span>
                        <span className="hidden-media" style={{ display: "block" }}>
                          {flavors[selFlavor]?.subQtyItems[idx]?.subQty}{" "}
                          {flavors[selFlavor]?.subQtyItems[idx]?.subQtyUnit}
                        </span>
                        <span className="week-price">
                          ($
                          {currencyFormat(
                            calDiscountedPricePerQuantity(
                              flavors[selFlavor].price,
                              `${enableDiscount ? discounts[idx].value : 0}`
                            )
                          )}
                          /{FLAVOR_UNIT[flavors[selFlavor]?.subQtyItems[0]?.subQtyUnit]})
                        </span>
                      </span>
                    </>
                  )}
                  {!flavors[selFlavor]?.subQtyItems?.length && (
                    <>
                      {idx + 1} <span className="hidden-media">{quantity}</span>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="product-details-steps select-frequency">
        <p className="subtitle">SELECT YOUR FREQUENCY</p>
        <div className="purchase-button-wrap">
          <div className="purchase-button-wrap-inner" onClick={() => setIsSubscribe(false)}>
            <div className={!isSubscribe ? "purchase-button active" : "purchase-button"}>
              <span>One-time Purchase</span>
              {(selectQuantityWeeks !== 1 || !flavors[selFlavor]?.isFlavor) &&
                discounted &&
                flavors[selFlavor]?.allowDiscount && (
                  <span className="disc-price-wrapper">
                    <span
                      className={`disc-price ${
                        (selectQuantityWeeks !== 1 || !flavors[selFlavor]?.isFlavor) &&
                        discounted &&
                        flavors[selFlavor]?.allowDiscount &&
                        !isSubscribe
                          ? "disc-price-red"
                          : ""
                      }`}
                    >
                      ${currencyFormat(calcDiscountedPrice(flavors[selFlavor].price))}
                    </span>
                  </span>
                )}
              {(selectQuantityWeeks || !flavors[selFlavor]?.isFlavor) && discounted && enableDiscount && (
                <span className={!isSubscribe ? "save active" : "save"}>
                  You save: $
                  {currencyFormat(
                    calcOrgPrice(flavors[selFlavor].price) - calcDiscountedPrice(flavors[selFlavor].price)
                  )}
                  {` ( ${discounts[selectQuantityWeeks - 1].value} % )`}
                </span>
              )}

              <div className="price">
                <span className={discounted && enableDiscount ? "main-price" : ""}>
                  {!enableDiscount ? (
                    <span>${currencyFormat(calcOrgPrice(flavors[selFlavor].price * quantity))}</span>
                  ) : (
                    <span>${currencyFormat(calcOrgPrice(flavors[selFlavor].price))}</span>
                  )}
                </span>
              </div>

              {/* When discount tiers are disabled we compare the discount from the price and the compare price */}
              {!enableDiscount && flavors[selFlavor].comparePrice > flavors[selFlavor].price && (
                <DiscountCompare
                  price={flavors[selFlavor].price}
                  comparePrice={flavors[selFlavor].comparePrice}
                  quantity={quantity}
                  isSubscribe={isSubscribe}
                />
              )}
            </div>
          </div>
          {initialDiscount !== 0 && (
            <div className="purchase-button-wrap-inner" onClick={() => setIsSubscribe(true)}>
              <div className={isSubscribe ? "purchase-button active" : "purchase-button"}>
                <span className="purchase-text">{"Subscribe and save "}</span>
                <span className="disc-price-wrapper">
                  <span className={`disc-price ${isSubscribe ? "disc-price-red" : ""}`}>
                    {!enableDiscount ? (
                      <span>${currencyFormat(calcSubDiscountedPrice(flavors[selFlavor].price * quantity))}</span>
                    ) : (
                      <span>${currencyFormat(calcSubDiscountedPrice(flavors[selFlavor].price))}</span>
                    )}
                  </span>
                </span>
                <span className={isSubscribe ? "save active" : "save"}>
                  You save: $
                  {currencyFormat(
                    calcDiscountedPrice(flavors[selFlavor].price) - calcSubDiscountedPrice(flavors[selFlavor].price)
                  )}{" "}
                  <span>{`( ${initialDiscount} % )`}</span>
                </span>
                <span className="price">
                  <span className="main-price">
                    {!enableDiscount ? (
                      <span>${currencyFormat(calcOrgPrice(flavors[selFlavor].price * quantity))}</span>
                    ) : (
                      <span>${currencyFormat(calcOrgPrice(flavors[selFlavor].price))}</span>
                    )}
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
        {showWhySubscribe && <WhySubscribeModal onClose={() => setShowWhySubscribe(false)} />}

        <p className="terms">
          {"No commitment. Cancel Anytime. "}
          <a href="#" className="link" onClick={() => setShowWhySubscribe(true)}>
            Learn More
          </a>
          <i className="icon-info"></i>
        </p>
      </div>

      {isSubscribe && (
        <div className="product-details-steps select-delivery">
          <p className="subtitle">DELIVER EVERY</p>
          <div className="weeks">
            {[
              {
                id: "gid://shopify/SellingPlan/18174345303",
                count: 15,
              },
              {
                id: "gid://shopify/SellingPlan/18174410839",
                count: 30,
              },
              {
                id: "gid://shopify/SellingPlan/18174443607",
                count: 45,
              },
              {
                id: "gid://shopify/SellingPlan/18174476375",
                count: 60,
              },
            ].map((plan, index) => {
              if (!plan) return null
              return (
                <div className="weeks-inner" key={index} onClick={() => handleSubscribe(plan.id)}>
                  <div className={plan.id === subscribeId ? "weeks-button active" : "weeks-button"}>
                    <span>{plan.count} days</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {subQtyItem && !enableDiscount && (
        <QuantityLabel itemQty={subQtyItem.subQty} totalQty={quantity} unitText={subQtyItem.subQtyUnit} />
      )}

      {flavors[selFlavor]?.displayNote && <BlockContent blocks={flavors[selFlavor]?._rawFlavorNote} />}

      <div className="add-to-cart">
        {!enableDiscount && (
          <QuantityPicker
            qty={quantity}
            qtyIncrease={() => setQuantity(quantity + 1)}
            qtyDecrease={() => quantity > 1 && setQuantity(quantity - 1)}
          />
        )}

        {isAddingProductInCart ? (
          <button className="btn btn-primary btn-disabled">
            <ClipLoader color={"white"} loading={true} size={30} />
          </button>
        ) : (
          <button
            className={`btn ${outOfStock ? "btn-secondary" : "btn-primary"}`}
            onClick={() => handleAddToCart()}
            disabled={outOfStock}
          >
            {outOfStock ? (
              "Out Of Stock"
            ) : !isSubscribe ? (
              <span>${totalPrice} - Add To Cart</span>
            ) : (
              "Setup Subscription"
            )}
          </button>
        )}
      </div>
      <div className="after-pay-wrapper text-right">
        <afterpay-placement
          data-locale="en_US"
          data-badge-theme="black-on-mint"
          data-amount={calcDiscountedPrice(flavors[selFlavor].price)}
        ></afterpay-placement>
      </div>
      <div className="extra-info-wrapper">
        <div className="extra-info-wrapper-inner">
          <span className="extra-info">
            <i className="icon-check xs"></i> {"30 Day Money Back Guarantee! "}
          </span>
          <span className="extra-info">
            <i className="icon-check xs"></i> {"Orders Dispatched Daily at 2pm PST "}
          </span>
        </div>
      </div>

      <BuildingBundleModal open={isBuildingBundleModalOpen} onClose={() => setIsBuildingBundleModalOpen(0)} />
    </>
  )
}
export default ProductInfoWrapper
